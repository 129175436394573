import {FixedUiElement} from "./FixedUiElement";

export default class Ornament extends FixedUiElement {

    constructor() {
        super("");
        this.SetClass("pt-3 pb-3 flex justify-center box-border")
    }


}